<template>
  <v-select
    v-model="selected"
    :items="countryCodes"
    item-value="code"
    item-text="name"
    class="border-all"
    solo
    dense
    flat
    return-object
    label="Код страны"
    outlined
    hide-details
    @change="updatePhoneMask"
  >
    <template #selection="{ item }">
      <div class="d-flex align-items-center">
        <img v-if="item.code == '+7'" src="@/assets/flag_kz.svg" class="mr-2" width="20px" />
        <img v-if="item.code == '+998'" src="@/assets/flag_uz.svg" class="mr-2" width="20px" />
        <img v-if="item.code == '+971'" src="@/assets/flag_ae.svg" class="mr-2" width="20px" />
        <div>{{ item.code }}</div>
      </div>
    </template>
    <template #item="{ item }">
      <div class="d-flex align-items-center">
        <img v-if="item.code == '+7'" src="@/assets/flag_kz.svg" class="mr-2" width="20px" />
        <img v-if="item.code == '+998'" src="@/assets/flag_uz.svg" class="mr-2" width="20px" />
        <img v-if="item.code == '+971'" src="@/assets/flag_ae.svg" class="mr-2" width="20px" />
        <div>{{ item.code }}</div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      countryCodes: [
        { name: 'Казахстан', code: '+7' },
        { name: 'Узбекистан', code: '+998' },
        { name: 'Арабские Эмираты', code: '+971' },
      ],
      selected: {
				code: ''
			},
      phone: ""
    }
  },
  computed: {
		...mapGetters({
			phone_code: "auth/GET_PHONE_CODE",
		}),
	},
  methods: {
    updatePhoneMask() {
			this.$store.dispatch("auth/savePhoneCode", this.selected.code)
      console.log('selected', this.selected)
      if (this.selected.code === '+7') {
        this.selected.mask = '+7-###-###-##-##'
      } else if (this.selected.code === '+998') {
        this.selected.mask = '+998-###-###-####'
      } else if (this.selected.code === '+971') {
        this.selected.mask = '+971-###-###-####'
      }
      this.$emit('change-mask', this.selected)
    },
  },
  created() {
		if(this.phone_code) {
			this.selected.code = this.phone_code
			this.updatePhoneMask()
		}
	},
}
</script>